.MessageHome
{
    position: relative;
    background-color: rgba(60,63,33,1);
    padding: 30px 10px;
    box-sizing: border-box;
    margin: 30px 0px 0px 0px;
    color:white;

    h1{
        font-family: Gunplay;
    }

    button {
        color:white!important;   
    }

    p{
        font-size: 1.1rem;
        line-height: 1.6rem;
        font-weight: 500;
    }


    &__img-container{
        text-align:  center;

        span{
            display: inline-block;
            height: 100%;
            vertical-align: middle;

            img{
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &:before{
        position: absolute;
        top:0px;
        left:0px;
        display: inline-block;
        //background-image: url('./images/warning_bar.png');
        background-size: auto 100%;
        content:'';
        width: 100%;
        height: 20px;
    }

    &:after{
        position: absolute;
        bottom: 0px;
        left:0px;
        display: inline-block;
        //background-image: url('./images/warning_bar.png');
        background-size: auto 100%;
        content:'';
        width: 100%;
        height: 20px;
    }
}