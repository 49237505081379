/*******************************************************************
                Header & Mobile Menu
*******************************************************************/


$nav-link-size: 18px;

.language-changer {
  color: $header-icons-color;
  font-size: $nav-link-size;
  border: 1px solid $navbar-light-toggler-border-color;
  margin-right: 6px;
  padding: 7px 10px 4px;
  border-radius: 0.25rem;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .media {
    display: flex;
    align-items: flex-start;
  }

  a {
    color: $header-icons-color;
  }

  .picker {
    position: absolute;
    background-color: $body-bg;
    opacity: .98;
    z-index: 20;
    margin: 100px 20px 0 0;
    padding: 0 8px;
    border-radius: 0.25rem;
    border: 1px solid $navbar-light-toggler-border-color;
    left: 0px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 16px;
    padding: 6px;

    img {
      height: 27px;
      margin-right: 0 !important;
    }

    .picker {
      display: flex;
      margin: 100px 0 0 -20px;
      padding: 0;

      > li {
        padding: 8px;

        img {
          height: 32px;
        }
      }
    }
  }

  .picker li:hover,
  a:hover {
    color: lighten($header-icons-color, 10%);
    text-decoration: none;
  }

  .picker li {
    list-style-type: none;
  }
}

.navbar {
  padding-top: 0;
  padding-left: 0 !important; // bootstrap override
  padding-right: 0 !important; // bootstrap override
}

.navbar-brand {
  font-family: 'Stencil Army WW I';
  font-size: 40px;
  text-transform: uppercase;
  overflow: hidden;
  line-height: 1;
  margin: -2px 0 0 0;
  padding-bottom: 0;

  .nav-bar-subtitle {
    font-size: 26px;
  }

  @include media-breakpoint-up(md){
    font-size: 70px;
  }
}


@media (max-width: 340px) {
  .navbar-brand {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .navbar-brand {
    .nav-bar-subtitle {
      font-size: 18px;
    }
  }
}

.navbar-nav {

  .nav-link {
    padding: 0;
    font-size: $nav-link-size;
  }

  .sub-cats {
    margin-top: -6px;

    a {
      text-decoration: none;
      font-size: 13px;

      &:not(:last-child) {
        margin-right: 9px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar-nav {
    flex-direction: row;
  }
}

.navbar-right-items {
  .nav-icon {
    border-radius: 0.25rem;
    border: 1px solid $navbar-light-toggler-border-color;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 2px;


    i {
      margin-top: 3px;
      font-size: 46px;
      color: $header-icons-color;

      @include media-breakpoint-down(md) {
        font-size: 30px;
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}

$main-cats-color: white;



.navbar-main-cats {
  background: url(./images/military-pattern.jpg);
  background-size: cover;
  padding: 0;
  margin-top: -4px;
  padding-bottom: 5px;
  padding-top: 6px;
  overflow: hidden;

  a {
    font-family: $military-font-family;
    text-decoration: none;
    text-transform: uppercase;
    color: $main-cats-color;
    font-size: 14px;

    @media only screen and (min-width: 1000px) {
      font-size: 19px;
    }

    @media only screen and (min-width: 1250px) {
      font-size: 24px;
    }

    &:hover {
      color: darken($main-cats-color, 30%);
      text-decoration: none;
    }
  }

  li:not(:last-child) {
    margin-right: 18px;
  }
}

/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // fix needed because media-body does not seem to take up space (width) from content
  .footer-text {
    min-width: 250px;
  }

  // fix needed because media-body does not seem to take up space (width) from content
  .language-changer-text {
    min-width: 90px;
  }
}
