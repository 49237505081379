
@media (min-width: 1587px) {
  .container-fluid {
    max-width: 1587px;
  }
}

.not-found {
  background: url("/img/bullet-holes.png") top right no-repeat fixed;

  h1 {
    font-family: Gunplay;
  }

  h2 {
    font-family: Gunplay;
    margin-top: 20px;
  }
}
