.search-page{
    margin: 20px 0px;
  
    h1{
      margin-bottom: 20px;
    }
  
    .page-item{
        &:hover{
          cursor: pointer;
        }
        &.active:hover{
            cursor:default;
        }
    }
  }

.searchbar
{
  position: relative;
  width: 100%;
  margin: 15px 0px;
  display: inline-block;
  font-size: 1.37rem;
  border: 3px solid #788b5e;
  border-radius: 10px;
 

    &__result{
      position: absolute;
      right: 0px;
      width: 350px;
      z-index: 99;
    }

    &__icon{
      position: absolute;
      z-index: 2;
      display: block;
      width: 2.7rem;
      height: 2.7rem;
      line-height: 2.7rem!important;
      text-align: center;
      pointer-events: none;

      background: url(./images/military-pattern.jpg); 
      color: white;
      border-radius: 5px 0px 0px 5px;
    }

    input{
      padding-left: 3.5rem;
      height: 2.7rem;
      width: 100%;
      background: transparent;
      border: 0px;
      color: white;

      &:focus{
        outline: none;
      }

      &::placeholder{
        color: #a3a0a0;
      }

    }
}