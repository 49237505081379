.categories-home {
  padding: 40px 0 10px 0;

  .caption {
    width: 100%;
    position: absolute;

    bottom: 1vw;
    @include media-breakpoint-down(md) {
      bottom: 1.5vw;
    }

    //background: #000;
    //background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 91%, rgba(0, 0, 0, 0.85) 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 91%, rgba(0, 0, 0, 0.85) 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 91%, rgba(0, 0, 0, 0.85) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d9000000', GradientType=0); /* IE6-9 */

    .text {
      color: $sand;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      font-family: $military-font-family;
      font-size: 3.0vw;

      @include media-breakpoint-down(xl) {
        font-size: 2.9vw;
        // color: red;
      }

      @include media-breakpoint-down(lg) {
        font-size: 4.5vw;
        // color: green;
      }

      @include media-breakpoint-down(md) {
        font-size: 4.2vw;
        // color: blue;
      }

      @include media-breakpoint-down(xs) {
        font-size: 9.0vw;
        // color: yellow;
      }
    }

    i {
      margin-left: 10px;
      font-size: 3.5vw;

      @include media-breakpoint-down(lg) {
        font-size: 5.0vw;
      }

      @include media-breakpoint-down(md) {
        font-size: 6vw;
      }

      @include media-breakpoint-down(xs) {
        font-size: 12.5vw;
      }
    }
  }

  a.text:hover {
    color: #e5e5e5;
    text-decoration: none;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }

  .thumbnail {
    position: relative;
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
}




// Corona:

.order-button {
  margin-top: 35px;

  &:hover img {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
  }

  img {
    -webkit-transform: rotate(13deg) scale(1.3);
    transform: rotate(13deg) scale(1.3);
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
  }
}
