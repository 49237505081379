@font-face {
  font-family: "Stencil Army WW I";
  src: url("./Stencil Army WW I.eot") format("eot"),
       url("./Stencil Army WW I.woff2") format("woff2"),
       url("./Stencil Army WW I.woff") format("woff"),
       url("./Stencil Army WW I.ttf") format("truetype"),
       url("./Stencil Army WW I.svg") format("svg");
}

// ATTN: The Oswald Stencil Bold is totally messed up: it renders below
//       its containing div. Tried playing with margins as a workaround
//       but it was still messed up on mobile devices
// -> So perhaps some of the font files are ok and only one is corrupt?
@font-face {
  font-family: "Oswald Stencil Bold";
  src: url("./OswaldStencil-Bold.eot") format("eot"),
       url("./OswaldStencil-Bold.woff2") format("woff2"),
       url("./OswaldStencil-Bold.woff") format("woff"),
       url("./OswaldStencil-Bold.ttf") format("truetype"),
       url("./OswaldStencil-Bold.svg") format("svg");
}

@font-face {
  font-family: 'Gunplay';
  src: url('./Gunplay-Regular.eot');
  src: url('./Gunplay-Regular.eot?#iefix') format('embedded-opentype'),
       url('./Gunplay-Regular.woff2') format('woff2'),
       url('./Gunplay-Regular.woff') format('woff'),
       url('./Gunplay-Regular.ttf') format('truetype'),
       url('./Gunplay-Regular.svg#Gunplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
