$menu-header-color: #fff;
$active-item-color: darken($body-color, 5%);
$border-color: #86877A;

/*******************************************************************
                SubCategoriesMenu
*******************************************************************/

.nav-section-menu.nav.sub-cats-menu .nav-section-menu.nav {
  margin-left: 0px;
  border-right: none;
}

.sub-cats-menu {
  margin-top: 10px;
  margin-bottom: 10px;
}

.social-media-icons-menu {
  margin-right: 16px;
  margin-top: 12px;
  text-align: right;

  a {
    margin-left: 10px;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}


/*******************************************************************
                CategoryTree
                // TODO: Not in use anymore: didn't render very nicely due high sub categories count
*******************************************************************/

.nav-section-menu.nav .nav-link {
  color: $body-color;
  border-radius: 0;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid $border-color;
  margin: -1px -1px 0 3px;
  background: darken($body-bg, 2%);
  text-shadow: none;
  padding: 3px 30px 3px 15px;
  position: relative;
  display: block;
}

.nav-section-menu.nav .nav-link.active,
.nav-section-menu.nav .nav-link.active:hover {
  border-left: 4px solid $active-item-color;
  border-right: 0px;
  background: $body-bg;
  color: $active-item-color;
}

.nav-section-menu.nav .nav-section-menu .nav-link:hover {
  background: linear-gradient(to right, lighten($body-bg, 5%), $body-bg);
}

.nav-section-menu.nav .nav-section-menu .nav-link {
  color: $body-color;
  font-weight: 400;
  font-size: 90%;
  background: $body-bg;
  padding: 2px 30px 2px 10px;
}

.nav-section-menu.nav .nav-link:hover i {
  color: $active-item-color !important;
}

.nav-section-menu.nav .nav-link i {
  font-size: 22px;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  right: 10px;
  color: $border-color;
}

.nav-section-menu.nav {
  padding: 2em 0;
  margin-left: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-right: 1px solid $border-color;
}

.nav-section-menu.nav .nav-header {
  padding-left: 5px;
  margin: 0;
  text-transform: uppercase;
  color: $menu-header-color;
  font-weight: 600;
  font-size: 11px;
  display: block;
}

.nav-section-menu.nav .nav-section-menu {
  padding: 0;
  margin-left: 10px;
}

.nav-section-menu.nav-section-menu-right.nav .nav-section-menu {
  margin-left: 0;
  margin-right: 10px;
}

.nav-main-cat-title {
  font-family: Gunplay;
  list-style: none;
  margin-bottom: -35px;

  color: darken($navbar-color, 10%);

  font-size: 32px;

  @include media-breakpoint-down(lg) {
    font-size: 22px;
    padding-top: 5px;
  }

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}
