.carousel {

  // carousel
  .d-block.img-fluid {
    margin-left: auto;
    margin-right: auto;
  }

  a.carousel-control-next, a.carousel-control-prev {
    cursor: pointer;
  }

  .carousel-indicators li {
    height: 5px;
    cursor: pointer;
  }
}
