// Military theme colors:
$subtext-color: lighten(#64655a, 5%);
$khaki-green: #2D2E18;
$sand: #C0B27D;
$purple: #b872b1;

// Military theme font: Changa One
$military-font-family: Gunplay;

// Color system
$primary:       #86877A;
$info:          #C0B27D;

// Body
$body-bg:                   #1E1F17;
$body-color:                #9ca324;

// Cards
$card-cap-bg:                       $primary;
$card-bg:                           $khaki-green;

$header-icons-color: darken(#7f7f7f, 15%);

$navbar-color: lighten(#64655a, 5%);
$navbar-light-color:                rgba($navbar-color,.5);
$navbar-light-hover-color:          rgba($navbar-color,.7);
$navbar-light-active-color:         rgba($navbar-color,.9);
$navbar-light-disabled-color:       rgba($navbar-color,.3);
$navbar-light-toggler-icon-bg:      url('../img/mobile-menu-opener.png');
$navbar-light-toggler-border-color: rgba($navbar-color,.1);
