/*******************************************************************
                Product Details Page
*******************************************************************/

$product-price-color: white;
$product-subtitles-color: lighten($khaki-green, 30%);
$product-hr-color: lighten($khaki-green, 10%);
$product-notices-color: $sand;

.product-page-container {
  margin-left: 15px;
  margin-right: 15px;
}

.product-banner {
  width: 100%;
  max-height: 55px;
  overflow: hidden;
  margin: 1.5rem 0;

  img{
    width: 100%;
    height: auto;
    margin-top:-15px;
    margin-left:-15px;
  }

  &-mobile{
    max-width: 100%;
    margin: 1rem 0;

    img{
      width: 100%;
      height: auto;
    }
  }



}

.product-page {
  >h1 {
    font-size: 5.9vw;
  }

  .product-price {
    color: $product-price-color;
    font-size: 20px;

    i {
      margin-right: 8px;
    }
  }

  .product-gallery {
    margin: auto;
    max-width: 500px;
  }

  .img-thumbs {
    .img-thumb {
      margin-top: 8px;
      width: 125px;
      display: inline-block;
      vertical-align: top;

      &:not(:last-child) {
        margin-right: 6px;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .open-gallery-btn {
    margin-top: 10px;
  }


  .product-group {
    h4 {
      font-size: 18px;
      color: $product-subtitles-color;
    }

    hr {
      border: 0;
      height: 1px;
      background: linear-gradient(to right, rgba($product-hr-color, 0.75) 50%, hsla(0, 0%, 0%, 0) 100%);
      margin-top: -7px;
    }

    >p {
      margin-top: -15px;
      font-size: 14px;
    }

    .spec-group {
      display: block;
    }

    .spec-title {
      margin-right: 7px;
      font-weight: bold;
    }
  }


  .product-desc {
    margin-top: 10px;
  }

  .product-notices {
    border: 2px solid white;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;

    color:white;
    font-size: 11px;

    h4 {
      font-size: 2em;
      font-family: 'Stencil Army WW I';
      text-transform: uppercase;

    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0px;

      li{
        font-size: 1.3em;
        line-height: 1.4em;
        font-weight: 500;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .product-page>h1 {
    font-size: 32px;
  }
}

@include media-breakpoint-up(md) {
  .product-page {
    padding-top: 8px;
  }
}
