.footer {
  background-color: $khaki-green;
  color: $subtext-color;
  //padding-top: 20px;

  strong {
    color: $sand;
  }

  .footer-stay-connected {
    padding-top: 15px;

    > div {
      display: inline;
    }
  }

  .cookie-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 20px;
    background: rgba(33, 29, 20, 0.9);
    font-size: 12px;
    z-index: 999;
  }

  .social-media-icons a {
    margin-right: 14px;
  }

  .footer-title {
    font-family: $military-font-family;
    font-size: 121%;
  }

  strong {
    color: $sand;
  }

  .footer-section {
    margin-top: 15px;
  }
}

.fb-post > span {
  max-width: 100%;
  width: 330px !important;
}

.fb-post iframe {
  border: 0;
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
