.OpeningHours {
  
    &--product {
      box-sizing: border-box;
      padding: 10px;
      background-color: black;
  
      font-family: 'Stencil Army WW I';
      text-transform: uppercase;
      color: white;
    }
  
  
    &__container {
  
      div:nth-child(2){
        box-sizing: border-box;
        text-align: center;
        padding: 10px;
      }
  
      @include media-breakpoint-up(xl) { 
        display: flex;
  
        >div {
          display: flex;
        }
    
        div:nth-child(1){
          width:70%;
        }
    
        div:nth-child(2){
          width: 30%;
        }
      }
  
     
  
      img{
        display: inline-block;
        margin:auto;
        max-height: 100px;
      }
    }
  
    table {
      tr td{
        padding-right:20px;
      }
    }
  
    @include media-breakpoint-down(lg) { 
        text-align: center;
  
        table{
          display: inline-block;
          margin:auto;
  
          tr {
            display: inline-block;
            width: 100%;
            margin: 10px 0px;
            td{
              display: block;
            }
          }
          
         
        }
    }
  }
  