/*******************************************************************
                Category Page
*******************************************************************/

.product-list,
.card-list {
  margin-bottom: 14px;
}

.card {
  width: 30%;
  margin-top: 6px;
  margin-left: 6px;

  border: 1px solid transparent;

  &:hover {
    border: 1px solid $khaki-green;
  }

  &-badge-price{
    @extend .badge;

    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0.5em;
    font-size: 14px;
    background-color: darken($sand, 25%);
    color: white;
  }

  &-badge-category {
    @extend .badge;

    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0.5em;
    font-size: 14px;
    background-color: darken($purple, 25%);
    color: white;
  }

  &-name{
    color: white;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 42px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    padding: 5px;
  }
}

.product-card {
  width: 30%;

  margin-top: 6px;
  margin-left: 6px;

  border: 1px solid transparent;

  &:hover {
    border: 1px solid $khaki-green;
  }

  .card-title {
    font-size: 14px;
  }

  .product-image-price {
    @extend .badge;

    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0.5em;
    font-size: 14px;
    background-color: darken($sand, 25%);
    color: white;
  }

  .product-card-name {
    color: white;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 42px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    padding: 5px;
  }
}


@include media-breakpoint-down(md) {
  .product-card {
    width: 48%;
  }
}

@include media-breakpoint-down(sm) {
  .product-card {
    width: 100%;
  }
}



/*************************** DROPDOWNS */

$dropdown-bg: #2D2E18;
$dropdown-color: #9C8619;

$pagination-bg: #2D2E18;
$pagination-bg-active: darken($pagination-bg, 10%);

.btn-dropdown {
  background-color: $dropdown-bg;
  color: $dropdown-color;
}

.dropdown-menu {
  background-color: $dropdown-bg;
}

.dropdown-menu button:hover {
  background-color: lighten($dropdown-bg, 10%);
}

.dropdown-menu .dropdown-item {
  color: $dropdown-color;
}




.pagination {
  .page-link {
    background-color: $pagination-bg;
    border-color: darken($pagination-bg, 8%);
  }

  .page-item.active .page-link {
    background-color: lighten($pagination-bg, 5%);
    border-color: darken($pagination-bg, 8%);
  }
}


/*************************** BREADCRUMB */

.category-breadcrumb {
  color: white;
  font-size: 16px;
  margin-bottom: 0;

  > i {
    margin-right: 6px;

    &:not(:first-child) {
      margin-left: 6px;
    }
  }

  a {
    color: white;

    &:hover,
    i:hover {
      text-decoration: underline;
    }
  }
}


/*************************** DEVICE CHANGES */


.category-page-xs {
  margin: 0;


  h1 {
    font-size: 5.9vw;
    font-family: Gunplay;
    margin-top: 12px;
  }


  .filters-btn {
    float: right !important;
    //@include float-right;
    cursor: pointer;
    border: 1px solid $navbar-light-toggler-border-color;
    border-radius: 0.25rem;
    padding: 0 8px;

    span {
      font-size: 4vw;
      margin-left: 10px;
    }

    i {
      font-size: 4vw;
    }

    &:hover {
      color: darken($body-color, 10%);
    }
  }


  .filters-pane {
    margin-top: 15px;
    font-size: 14px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 6px;
    }
  }
}

.category-toolbar {
  margin: auto;
  max-width: 500px;
  width: 500px;
  margin: 15px auto 20px;
}

@include media-breakpoint-up(sm) {
  .filters-pane {
    font-size: 16px;
  }
}



/*************************** SUB CATEGORY CLOUD */

.sub-cats-cloud {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  margin-bottom: 24px;
  min-height: 20px;

  a:not(:last-child) {
    margin-right: 18px;
  }
}





/*******************************************************************
                Catalog Page (all categories)
*******************************************************************/
$main-cats-color: $body-color;


.catalog-page {
  margin-left: 15px;
  margin-right: 15px;

  .main-cat {
    display: flex;
    margin-left: 25px;

    &:first-child {
      margin-top: 24px;
    }

    .main-cat-link {
      font-family: $military-font-family;
      letter-spacing: 1px;
      color: $main-cats-color;
      font-size: 23px;
      line-height: 1.5;
      padding: 0;
      overflow: hidden;
      margin: 0px;

      &:hover {
        text-decoration: none;
        color: darken($main-cats-color, 20%);
      }

      i {
        margin-right: 8px;
        font-size: 28px;
      }
    }
  }
}
